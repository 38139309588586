import React from 'react'
import Helmet from 'react-helmet'
import Footer from '../components/footer/footer'
import Header from '../components/header/header'
import { getMainMenu } from '../lib/helpers'
import { Main } from './layoutStyles'

const Layout = ({pageContext, children, path}) => {
    const {menu, settings, title} = pageContext;
    const mainMenu = getMainMenu(menu);

    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang: `en`,
                }}
                title={`${title ? `${title} | ` : ''}${settings?.title}`}
                description={settings?.description}
            >
                <meta name={`description`} content={settings?.description} />
                <meta name={`keywords`} content={settings?.keywords} />

                <meta name={`og:title`} content={settings?.socialTitle} />
                <meta name={`og:description`} content={settings?.socialDescription} />
                <meta name={`og:type`} content={`website`} />
                <meta name={`og:image`} content={settings?.image?.asset?.url} />

                <meta name={`twitter:card`} content={`summary`} />
                <meta name={`twitter:title`} content={settings?.socialTitle} />
                <meta name={`twitter:description`} content={settings?.socialDescription} />

                <meta
                    name={`viewport`}
                    content={`width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=0,viewport-fit=cover`}
                />

                <link rel={`alternate`} hrefLang={`x-default`} href={`https://www.pariscat.com.au${path}`} />
                <link rel={`alternate`} hrefLang={`en`} href={`https://www.pariscat.com.au${path}`} />
                <link rel={`canonical`} href={`https://www.pariscat.com.au${path}`} />

                <script type={`application/ld+json`}>
                    {`{
                        "url":"https://www.pariscat.com.au",
                        "name":"${title ? `${title} | ` : ''}${settings?.title}",
                        "description":"${settings?.description}",
                        "image":"${settings?.image?.asset?.url}",
                        "@context":"http://schema.org",
                        "@type":"WebSite"
                    }`}
                </script>
                <meta name={`google-site-verification`} content={`29RdcGCPVBIWK7YyH23W-nf2PAHg2ej_Qpvh2006HTU`} />

                <script defer src={`https://www.googletagmanager.com/gtag/js?id=G-TVD9TRDF8W`}></script>
                <script defer>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-TVD9TRDF8W');
                    `}
                </script>

            </Helmet>
            <Header links={mainMenu} />
            <Main>
                {children}
            </Main>
            <Footer />
        </>
    )
}

export default Layout
