import React from 'react'
import { FooterContainer, FooterRow, BrandLink, BrandImg, TextLink, InternalLink, Copyright, SocialLink } from './footerStyles'

const Footer = () => {
    return (
        <FooterContainer>
            <FooterRow>
                <SocialLink href={`https://www.facebook.com/pariscatjazzclub/`} target={`_blank`} title={`Facebook`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310" xmlSpace="preserve" fill={`currentColor`}><path d="M81.703 165.106h33.981V305a5 5 0 0 0 5 5H178.3a5 5 0 0 0 5-5V165.765h39.064a5 5 0 0 0 4.967-4.429l5.933-51.502a5 5 0 0 0-4.966-5.572h-44.996V71.978c0-9.732 5.24-14.667 15.576-14.667h29.42a5 5 0 0 0 5-5V5.037a5 5 0 0 0-5-5h-40.545A39.746 39.746 0 0 0 185.896 0c-7.035 0-31.488 1.381-50.804 19.151-21.402 19.692-18.427 43.27-17.716 47.358v37.752H81.703a5 5 0 0 0-5 5v50.844a5 5 0 0 0 5 5.001z"/></svg>
                </SocialLink>
                <SocialLink href={`https://www.instagram.com/pariscatjazz/`} target={`_blank`} title={`Instagram`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 169 169" xmlSpace="preserve" fill={`currentColor`}><path d="M122.406 0H46.654C20.929 0 0 20.93 0 46.655v75.752c0 25.726 20.929 46.655 46.654 46.655h75.752c25.727 0 46.656-20.93 46.656-46.655V46.655C169.063 20.93 148.133 0 122.406 0zm31.657 122.407c0 17.455-14.201 31.655-31.656 31.655H46.654C29.2 154.063 15 139.862 15 122.407V46.655C15 29.201 29.2 15 46.654 15h75.752c17.455 0 31.656 14.201 31.656 31.655v75.752z"/><path d="M84.531 40.97c-24.021 0-43.563 19.542-43.563 43.563 0 24.02 19.542 43.561 43.563 43.561s43.563-19.541 43.563-43.561c0-24.021-19.542-43.563-43.563-43.563zm0 72.123c-15.749 0-28.563-12.812-28.563-28.561 0-15.75 12.813-28.563 28.563-28.563s28.563 12.813 28.563 28.563c0 15.749-12.814 28.561-28.563 28.561zM129.921 28.251c-2.89 0-5.729 1.17-7.77 3.22a11.053 11.053 0 0 0-3.23 7.78c0 2.891 1.18 5.73 3.23 7.78 2.04 2.04 4.88 3.22 7.77 3.22 2.9 0 5.73-1.18 7.78-3.22 2.05-2.05 3.22-4.89 3.22-7.78 0-2.9-1.17-5.74-3.22-7.78-2.04-2.05-4.88-3.22-7.78-3.22z"/></svg>
                </SocialLink>
            </FooterRow>
            <FooterRow>
                <BrandLink href={`https://en-au.sennheiser.com/`} title={`Sennheiser`} target={`_blank`} rel={`noopener`}>
                    <BrandImg srcSet={'/img/sennheiser.png 1x, /img/sennheiser_2x.png 2x'} alt={`Sennheiser`} width={112} height={12} />
                </BrandLink>
                <BrandLink href={`https://www.drumtek.com.au/`} target={`_blank`} title={`Drumtek`} rel={`noopener`}>
                    <BrandImg srcSet={'/img/drumtek.png 1x, /img/drumtek_2x.png 2x'} alt={`Drumtek`} width={112} height={32} />
                </BrandLink>
                <BrandLink href={`https://au.yamaha.com/index.html`} title={`Yamaha`} target={`_blank`} rel={`noopener`}>
                    <BrandImg srcSet={'/img/yamaha.png 1x, /img/yamaha_2x.png 2x'} alt={`Yamaha`} width={112} height={25} />
                </BrandLink>
                <BrandLink href={`http://www.markbass.it/`} title={`Mark Bass`} target={`_blank`} rel={`noopener`}>
                    <BrandImg srcSet={'/img/mark.png 1x, /img/mark_2x.png 2x'} alt={`Mark Bass`} width={112} height={33} />
                </BrandLink>
                <BrandLink smaller={true} href={`https://onemusic.com.au/`} title={`One Music - Proud To Play`} target={`_blank`} rel={`noopener`}>
                    <BrandImg srcSet={'/img/proud.png 1x, /img/proud_2x.png 2x'} alt={`One Music - Proud To Play`} width={112} height={112} />
                </BrandLink>
            </FooterRow>
            <FooterRow>
                <TextLink href={`http://www.scottphotographics.com/`} title={`Scott Photographics`} target={`_blank`} rel={`noopener`}>
                    Scott Photographics
                </TextLink>
                <TextLink href={`http://www.straightlinephotography.com.au/`} target={`_blank`} rel={`noopener`} title={`Straightline Photography`}>
                    Straightline Photography
                </TextLink>
                <TextLink href={`http://melbournesbarsandpubs.com.au/smartphone/`} title={`Melbourne Bars and Pubs`} target={`_blank`} rel={`noopener`}>
                    Melbourne Bars and Pubs
                </TextLink>
            </FooterRow>
            <FooterRow>
                <InternalLink to={`/privacy-policy`} title={`Privacy Policy`}>
                    Privacy Policy
                </InternalLink>
                <InternalLink to={`/terms-conditions`} title={`Terms & Conditions`}>
                    Terms & Conditions
                </InternalLink>
            </FooterRow>
            <FooterRow last={true}>
                <TextLink href={`https://goo.gl/maps/jF43sF5J1CuAe71m6`} title={`6 Goldie Place, Melbourne VIC 3000`} target={`_blank`} rel={`noopener`}>
                    6 Goldie Place, Melbourne VIC 3000
                </TextLink>
                <Copyright>
                    &copy; Paris Cat Jazz Club Pty Ltd {new Date().getFullYear()}
                </Copyright>
            </FooterRow>
        </FooterContainer>
    )
}

export default Footer
