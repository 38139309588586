import React, { useState, useMemo } from 'react'
import { HeaderContainer, Inner, LogoLink, Logo, MenuToggle, LinkWrapper, MenuLink } from './headerStyles'

const Header = ({links}) => {
    const [active, setActive] = useState(false);

    return useMemo(() => (        
        <HeaderContainer role={`navigation`} aria-label={`main-navigation`}>
            <Inner>
                <LogoLink to="/" title={`Paris Cat Jazz Club`} target={`_self`} onClick={() => setActive(false)}>
                    <Logo 
                        srcSet={'/img/logo_1x.png 1x, /img/logo_2x.png 2x, /img/logo_3x.png 3x'} 
                        src={'/img/logo_1x.png'} 
                        alt={`Paris Cat Jazz Club`}
                    />
                </LogoLink>
                {links?.length ? (
                    <MenuToggle aria-label={`Menu`} onClick={() => setActive(!active)}>
                        {active ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width={`24`} height={`24`} x="0px" y="0px" fill={`#ECC94B`} viewBox="0 0 512.001 512.001"><path d="M284.286 256.002L506.143 34.144c7.811-7.811 7.811-20.475 0-28.285-7.811-7.81-20.475-7.811-28.285 0L256 227.717 34.143 5.859c-7.811-7.811-20.475-7.811-28.285 0-7.81 7.811-7.811 20.475 0 28.285l221.857 221.857L5.858 477.859c-7.811 7.811-7.811 20.475 0 28.285a19.938 19.938 0 0014.143 5.857 19.94 19.94 0 0014.143-5.857L256 284.287l221.857 221.857c3.905 3.905 9.024 5.857 14.143 5.857s10.237-1.952 14.143-5.857c7.811-7.811 7.811-20.475 0-28.285L284.286 256.002z"/></svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" width={`32`} height={`32`} x="0px" y="0px" fill={`#ECC94B`} viewBox="0 0 384.97 384.97"><path d="M12.03 120.303h360.909c6.641 0 12.03-5.39 12.03-12.03 0-6.641-5.39-12.03-12.03-12.03H12.03c-6.641 0-12.03 5.39-12.03 12.03s5.39 12.03 12.03 12.03zM372.939 180.455H12.03c-6.641 0-12.03 5.39-12.03 12.03s5.39 12.03 12.03 12.03h360.909c6.641 0 12.03-5.39 12.03-12.03s-5.389-12.03-12.03-12.03zM372.939 264.667H132.333c-6.641 0-12.03 5.39-12.03 12.03 0 6.641 5.39 12.03 12.03 12.03h240.606c6.641 0 12.03-5.39 12.03-12.03.001-6.641-5.389-12.03-12.03-12.03z"/></svg>
                        )}
                    </MenuToggle>
                ) : null}
                {links?.length ? (
                    <LinkWrapper active={active}>
                        {links?.map((link, i) => (
                            <MenuLink 
                                key={link.title} 
                                to={link.url} 
                                title={link.title} 
                                target={link?.external ? '_blank' : '_self'}
                                index={i}
                                style={{ transition: `all ${0.5 + (0.1 * (i + 1))}s ease`}}
                                onClick={() => setActive(false)}
                            >
                                {link.title}
                            </MenuLink>
                        ))}      
                    </LinkWrapper>
                ) : null}
            </Inner>
        </HeaderContainer>
    ), [active, links]);
}

export default Header
