import tw, {styled} from 'twin.macro';
import { Link } from 'gatsby'

export const FooterContainer = tw.footer`w-full p-4 md:px-8 py-8 md:py-16 bg-grey-dark block`;
export const FooterRow = styled.div`
    ${tw`w-full px-4 md:px-8 text-white font-sans text-center max-w-4xl mx-auto flex items-center justify-center flex-wrap`}
    ${({last}) => last ? tw`pb-0` : tw`pb-8`}
`;
export const BrandLink = styled.a`
    ${tw`p-2 block`}
    ${({smaller}) => smaller ? tw`w-16` : tw`w-32`}
`
export const BrandImg = tw.img`w-full`
export const TextLink = tw.a`px-2 block underline hover:text-gold-dark transition-all duration-500`
export const InternalLink = tw(Link)`px-2 block underline hover:text-gold-dark transition-all duration-500`
export const Copyright = tw.p`text-white pr-2`
export const SocialLink = styled.a`
    ${tw`text-white hover:text-gold h-8 w-8 mx-4 md:mb-4 transition-all duration-500`}

`