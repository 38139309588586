import tw, { styled, css } from 'twin.macro';
import { Link } from 'gatsby'

export const HeaderContainer = tw.header`w-full sticky top-0 left-0 z-50`;
export const Inner = tw.div`w-full p-4 lg:pl-8 bg-grey-dark flex items-center justify-between`;
export const LogoLink = tw(Link)`block w-1/2 lg:w-1/4`
export const Logo = tw.img`flex h-12 lg:h-20`;
export const MenuToggle = tw.button`lg:hidden flex items-center justify-end flex-wrap w-8 outline-none focus:outline-none`
export const LinkWrapper = styled.nav`
    ${tw`bg-grey-dark fixed right-0 z-20 top-20 lg:top-0 lg:relative w-full lg:w-3/4 flex items-center justify-end flex-wrap transition-all duration-500`}
    ${({active}) => active ? css`
        transform: translateX(0);
        a {
            transform: translateX(0);
        }
    ` : css`
        transform: translateX(100%);
        a {
            transform: translateX(100%);
        }
        @media(min-width: 1024px){
            transform: translateX(0);
            a {
                transform: translateX(0);
            }
        }
    `}
`
export const MenuLink = tw(Link)`w-full lg:w-auto font-thin font-serif text-gold hover:text-red text-24 p-4 transition-all duration-500`